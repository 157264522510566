import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape } from '../../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../../routing/routeConfiguration';
import { propTypes } from '../../../../util/types';
import {
  Avatar,
  InlineTextButton,
  LinkedLogo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  Button,
} from '../../../../components';

import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';

import css from './TopbarDesktop.module.css';
import magnifying_glass from '../../../../assets/magnifying_glass.png';
import heart from '../../../../assets/heart.png';
import cart from '../../../../assets/cart.png';
import SideFormComponent from '../../../../components/CustomSection/SideForm';
import { createSlug } from '../../../../util/urlHelpers';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import OverlayMenuComponent from '../../../../components/CustomSection/OverlayMenu';
//

const TopbarDesktop = props => {
  const {
    className,
    appConfig,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    handleShowSideForm,
    quantity,
    prices,
    total,
    wishlistCount,
   routeConfiguration,
  } = props;

  const location = useLocation();
  const history = useHistory();

  const path = location.pathname;
  
  const [mounted, setMounted] = useState(false);
  const [showOverlayMenu, setShowOverlayMenu] = useState(false);


  const marketplaceName = appConfig.marketplaceName;
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = classNames(rootClassName || css.root, className);
  const [isLandingPage, setIsLandingPage] = useState(false);

  const displayName = currentUser?.attributes?.profile?.displayName;


  useEffect(() => {
    setMounted(true);

    if(path==="/"){
      setIsLandingPage(true);
    }else{
      setIsLandingPage(false);
    }

  }, []);



  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      appConfig={appConfig}
    />
  );

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="ManageListingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.yourListingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.signupLink}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.loginLink}>
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );

  const handleShopClick = (event)=>{
    console.log("clicke level 1 -----------");
    history.push("/s");
  }

  const handleWomanClick = (event)=>{
    console.log("clicke level 1 -----------");
    history.push("/s?pub_category=women")
  }

  const handleGirlClick = (event)=>{
    console.log("clicke level 1 -----------");
    history.push("/s?pub_category=girls");
  }

  const handleBoyClick = (event)=>{
    console.log("clicke level 1 -----------");
    history.push("/s?pub_category=boys_and_men");
  }

  const handleDigitalClick = (event)=>{
    console.log("clicke level 1 -----------");
    history.push("/s?pub_category=digital_goods");
  }

  const handleAccessClick = (event)=>{
    console.log("clicke level 1 -----------");
    history.push("/s?pub_category=accessories");
  }

  const handleShoeClick = (event)=>{
    console.log("clicke level 1 -----------");
    history.push("/s?pub_category=shoes");
  }

  const handleRequestClick = (event)=>{
    console.log("clicke level 1 -----------");
    history.push("/s?pub_category=request");
  }

  const handleShowMenu = ()=>{
    console.log("Mouse detected -------------------------------");
    setShowOverlayMenu(!showOverlayMenu)
  }

  const handleWishlistClicked = (event)=>{
    console.log("clicke level 1 -----------");
    history.push("/my-account/wishlist");
  }

//
  return (
    <>
    
    <nav className={css.nav}>
      <div className={css.nav_item}>
         <Button
          name="SearchPage" 
          className={css.btnlink}
          onClick={handleShopClick}
         >
          SHOP ALL
        </Button>
        <Button
          name="SearchPage" 
          className={css.btnlink}
          onClick={handleWomanClick}
         >
          WOMENS
        </Button>
        <Button
          name="SearchPage" 
          className={css.btnlink}
          onClick={handleGirlClick}
         >
          GIRLS
        </Button>

        <Button
          name="SearchPage" 
          className={css.btnlink}
          onClick={handleBoyClick}
         >
          BOYS & MENS
        </Button>

        <Button
          name="SearchPage" 
          className={css.btnlink}
          onClick={handleDigitalClick}
         >
          DIGITAL GOODS
        </Button>

        <Button
          name="SearchPage" 
          className={css.btnlink}
          onClick={handleAccessClick}
         >
          ACCESSORIES & MORE
        </Button>

        <Button
          name="SearchPage" 
          className={css.btnlink}
          onClick={handleShoeClick}
         >
          SHOES
        </Button>
      </div>
      
      <LinkedLogo layout={'mobile'} alt={intl.formatMessage({ id: 'Topbar.logoIcon' })} />
     
      <div className={css.nav_item_end}>
        <div className={css.menuRow}>

            {isAuthenticated?
            <div>
                {
                  showOverlayMenu?
                  <div className={css.overlayMenu}>
                    <OverlayMenuComponent  onLogout={onLogout}/>
                  </div>:""
                }

                <div className={classNames('d-flex gap-3 justify-content-center align-items-center',css.textSize)}>
                 
                    
                    <Button
                      name="SearchPage" 
                      className={css.btnlink}
                      
                  >
                      {displayName}
                  </Button>
                    
                  
                  <Button
                      name="SearchPage" 
                      className={css.btnlink}
                      onClick={handleShowMenu}
                  >
                      MY ACCOUNT
                  </Button>
                </div>
            </div>
             
            :
            <>
                <Button
                  name="SearchPage" 
                  className={css.btnlink}
                  onClick={handleRequestClick}
                >
                  REQUEST FOR QUOTE
                </Button>

                {
                  isLandingPage?
                  <div>
                  <Button
                  name="SearchPage" 
                  className={css.btnlink}
                  onClick={handleShowSideForm}
                  >
                    LOGIN/REGISTER
                  </Button>
                  
                </div>:
                  <NamedLink name="LoginPage">LOGIN/REGISTER</NamedLink>
                }
               
            </>
            
            }
          
              <div>
                <NamedLink name="SearchPage">
                  <img src={magnifying_glass} />
                </NamedLink>
              </div>
              <div className={css.heart}>
                <Button className={css.transBg} onClick={handleWishlistClicked} >
                  
                  <div className={css.count}>
                    {wishlistCount}
                  </div>
                  <img src={heart} />
                </Button>
               
              </div>
              <div>
                <NamedLink onClick={handleShowSideForm} name="CartPage">
                  <div>
                      <span className={css.price}>{quantity} / ${total}</span>  
                  </div>
                  <img src={cart} />
                </NamedLink>
              </div>

              
              
        
        </div>
       

      </div>
     
    </nav>

    
     
    </>
    
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  appConfig: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  appConfig: object,
};

export default TopbarDesktop;
